import React, { useState } from 'react';
import '../css/ginput.css';


import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';


function GInput(props) {
    const [staySigned, setStaySigned] = useState(false)

  return (
    <div style={{marginTop:49, marginLeft:27, flex:1}}>
     <div className='inputtext'>
      <div className='lefticon'>{props.leftIcon}</div>
      <input {...props}  />
     <div className='righticon'>{props.rightIcon}</div> 
    </div>
    
</div>
  )
}

export default GInput