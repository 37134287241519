import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faRefresh} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';


function Dashboard() {
    const navigate = useNavigate();
    const { profile, token, setProfile, setActivePair, setActiveLevel } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [game, setGame]=useState(null);
    const [level, setLevel] =useState(null);
    const [interest, setInterest]=useState(null);
    const [pair, setPair] =useState(null);
    const [balance, setBalance]=useState(0);
    const [isInterested, setIsInterested]=useState(false);
    const [isJoin, setIsJoin]=useState(false); //this is for when interest is completed
    const [errmsg, setErrmsg]=useState('');


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/dashboard`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                setGame(data.game); setLevel(data.level); setInterest(data.gameInterest); setPair(data.gamePair); setBalance(data.currentbal);
                setActivePair(data.gamePair);
                setActiveLevel(data.level);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
   const onInterest=async()=>{
   
    setErrmsg('');
      setLoading(true);
     
      try {
        var v={levelId:level.id,gameId:game.id,customerId:profile.id,status:'Ready'}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game/showinterest`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
          //on success, show dialog and msg
             setInterest(data.gi);
         setIsInterested(false);
         setIsJoin(true);
         setPair(data.pair);
        } else{
          setErrmsg(data.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
       // setErr(Constants.serverErr);
      
       // onMove();
      }
   
   }

   const onRefresh=()=>{
    Load();
   }

const onStartGame=()=>{
    navigate('/customer/game/pair');
   }
  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
    <div className='title'>
        <h3>Welcome {profile?.firstname} {profile?.lastname}!</h3>
        <div onClick={onRefresh}><FontAwesomeIcon icon={faRefresh}  /> Refresh</div>
        {game!=null && level!=null && level.levelNo==1 && interest==null && <button className='btn_primary' onClick={()=>setIsInterested(true)}>Show Interest</button>} 
    </div>
    
{game!=null && <div className='activegame'>
        <div>
            <div>Game Starts </div>
            <div>
            <FontAwesomeIcon icon={faUser}  />
            <span>Current Level:  <strong>{level.levelNo}</strong></span>
            </div>
        </div>
        <div className='time'>
            <span>{Constants.formatDate(level.startTime)}</span>
            <span>{Constants.formatTime(level.startTime)}</span>
        </div>
        <div>
           {level?.status=="ongoing" && interest?.status=='pair' && <div style={{textAlign:'right'}}><button className='btn_pry_red' onClick={onStartGame}>Start</button></div>} 
           {/*  <div style={{textAlign:'right'}}>
            <FontAwesomeIcon icon={faUser}  />
            <span>Interested Member: <strong>14</strong></span>
            </div> */}
        </div>
    </div>}
    
    <div className='innerBox'>
        <div><FontAwesomeIcon icon={faGift}  /><span> Wallet</span></div>
        <div><span>{Constants.formatCurrency(balance)}</span></div>
    </div>
   {interest!=null && interest?.status !='pair' && <div  className='innerBox'>
       {interest?.status?.toLowerCase()=='ready' && <p>Waiting for Pairing</p>} 
       {interest?.status?.toLowerCase()=='lost' && <p style={{color:'red'}}>You lost </p>} 
       {interest?.status?.toLowerCase()=='cashout' && <p>You Cash out</p>} 
       {interest?.status?.toLowerCase()=='expired' && <p>Your did not come to play</p>} 
    </div>} 
        {pair!=null && <div className='innerBox'>
                
                <div className='pairs'>
                    <div className='pair'>
                        <h3>{pair.pairA.firstname}</h3>
                        <span>{pair.pairA.phone}</span>
                    </div>
                    <p>VS</p>
                    <div className='pair'>
                    <h3>{pair.pairB.firstname}</h3>
                    <span>{pair.pairB.phone}</span>
                    </div>
                </div>
            </div>}
    
    <div className='innerBox'>
        <Link>
            <img src={img1} style={{height:70}} />
            <p>Fund Wallet</p>
            </Link>
        
            <Link>
            <img src={img6} style={{height:70}} />
            <p>Fund Wallet (Transfer)</p>
            </Link>
            <Link>
            <img src={img7} style={{height:70}} />
            <p>Register for Game</p>
            </Link>
            <Link>
            <img src={img2} style={{height:70}} />
            <p>Account Statement</p>
            </Link>
            <Link>
            <img src={img8} style={{height:70}} />
            <p>Withdraw from Wallet</p>
            </Link>
            <Link>
            <img src={img9} style={{height:70}} />
            <p>Logout</p>
            </Link>

    </div>
    </div>
    <GDialog open={isInterested} text={`Note:  You have indicated your interest to Paticipate in the game,  
            one time fee of N200 will be charge from your wallet  to be qualify for the game. Kindly fund your wallet with a  minimum of ${Constants.formatCurrency(game?.amount) }`}
             isAlert={false} onProceed={onInterest} onCancel={()=> setIsInterested(false)} errmsg={errmsg} loading={loading} />
     
     <GDialog open={isJoin} text={`You are now waiting to play the game at the schedule time. We hope to pair you soon or you already paired. Check your dashboard` } isAlert={true} onProceed={()=> setIsJoin(false)} />
         
    </div>

  )
}

export default Dashboard