import React, { useState , useEffect, useContext} from 'react';



import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import { faSignalMessenger } from '@fortawesome/free-brands-svg-icons/faSignalMessenger';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';



function GCustomerHeader(props) {

    
    useEffect(() => {
      
     }, []);

     const navigate = useNavigate();
     const onLogin=()=>{
         navigate('/login');
     }
     const onRegister=()=>{
        navigate('/register');
    }
  return (
    <div className='homebar'>
        
    <div className='logoside'>
      <img src={Logo}/> 
      <span>TWO LEAVES GAMES</span>
    </div>
    
    <div className='menu'>
      <ul>
        <li className={props.active=='dashboard'?'underline':''}><Link to="/">Dashboard</Link></li>
        <li className={props.active=='feedback'?'underline':''}><Link to="/"><FontAwesomeIcon icon={faMessage}  /></Link></li>
        <li className={props.active=='profile'?'underline':''}><Link to="/"><FontAwesomeIcon icon={faUser}  /></Link></li>
        
      </ul>
    </div>
  </div>
  )
}

export default GCustomerHeader